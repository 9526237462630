.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contact-title {
    font-size: 48px;
    font-weight: bold;
    margin: 5%;
}

#contact-info, #email {
    font-size: 32px;
}

#contact-info {
    margin-bottom: 3%;
}

.contact-text {
    margin-bottom: 7%;
    display: flex;
    flex-direction: column;

}

@media screen and (max-width: 700px) {
    #contact-info, #email {
        font-size: 24px;
    }
}

@media screen and (max-width: 520px) {
    #contact-info, #email {
        font-size: 16px;
    }

    .contact-title {
        font-size: 32px;
    }
}