#root {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5; 
}