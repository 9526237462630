#resume-title {
    font-weight: 800;

    margin: 2%;

}

.resume-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.resume-main-container {
    display: flex;
    flex-direction: row;
}

.resume-main-content-container {
    width: 90%;
    margin-left: 2%;
    margin-right: 2%;

}

.resume-anchor-container {
    width: 10%;

}


@media screen and (max-width: 600px) {
    .resume-anchor-container {
        display: none;
    }

    .resume-main-content-container {
        width: 100%;
    }
}