.avatar-container {
    align-items: center;
    display: flex;
}

.avatar-name-container {
    text-decoration: none;
}

.avatar-name {
    margin-left: 10px;
    color: white;
}

@media screen and (max-width: 780px) {
    .avatar-name-container {
        display: none;
    }
}

.avatar-link {
    display: inline-block;
}