.main-content {
    display: flex;
    flex-direction: row;
}

.Intro {
    width: 93%;
    color: black;
    font-size: 24px;
    font-family: 'Helvetica';
}

.intro-wrapper {
    margin-left: 2%;
    margin-right: 2%;
}

.anchor-container {
    position: fixed;
    right: 0;
    width: 7%;

}

.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before {
    border-inline-start: 2px solid #807e7e
}

.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
    color: black;
}

.intro-name {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'Comic Sans MS', Tahoma, Geneva, Verdana, sans-serif;
    flex-direction: row;
    color: black;
}

@media (max-width: 630px) {
    .intro-name {
        font-size: 1.5em;
    }
}

.english-name .chinese-name {
    text-align: center;
}

.english-name {
    padding-right: 2%;
    display: flex;
}

.chinese-name {
    padding-left: 2%;
    display: flex;
}


#intro-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2%;
}

.alpaca-pic {
    flex: 1;
    display: flex;
    justify-content: left;
    width: 40%;
    height: auto;
}

.alpaca-text {

    color: black;
    width: 60%;
    align-items: left;
    justify-content: left;
    margin-top: 3%;
    margin-bottom: 2%;
    margin-left: 2%;
    /* margin-right: 4%; */
    font-size: 20px;
    line-height: 1.5;

}
#about-me, #about-shanghai {
    color: black;
}


.hobby-list, .shanghai-list {
    margin-top: 1%;
    margin-bottom: 2%;
}

#likes {
    color: black;
    font-size: 24px;
    text-align: left;
}

.list-header {
    font-size: 24px;
}

.ant-divider-plain.ant-divider-with-text {
    color: black;
    margin-top: 16px;
    margin-bottom: 16px;

}


.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before, .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after  {
    border-color: black;
}


@media screen and (max-width: 768px) {
    .alpaca-text, #about-me, #about-shanghai, #likes, .facts-list, .shanghai-list, .hobbies-list{
      font-size: 24px; 
    }

    #intro-text {
        flex-direction: column;
        align-items: center;

    }

    .alpaca-text, .alpaca-pic {
        margin-right: 1%;
        width: 96%;
    }

    .alpaca-pic {
        margin-bottom: 1%;
    }

    .Intro {
        width: 90%;
    }

    .intro-wrapper {
        margin-left: 5%;
        margin-right: 5%;
    }

    .anchor-container {
        width: 10%;
    }
}

@media screen and (max-width: 480px) {

    .intro-name {
        flex-direction: column;
    }

    .alpaca-text, #about-me, #about-shanghai, #likes, .facts-list, .shanghai-list, .hobbies-list {
      font-size: 16px; 
    }
    #intro-text {
        flex-direction: column;
        align-items: center;

    }

    .alpaca-text, .alpaca-pic {
        margin-right: 1%;
        width: 96%;
    }

    .alpaca-pic {
        margin-bottom: 1%;
    }

    .Intro {
        width: 100%;
    }

    .anchor-container {
        /* width: 15%; */
        display: none;
    }

}

