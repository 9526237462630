.navbar-list {
  display: flex;
  align-items: center; 
  justify-content: center;
  flex-grow: 1;
  height: 100px;
}

.navbar-list li a {
  text-decoration: none;
  color: white;
}


@media screen and (min-width: 500px) {
  .navbar-icon {
    display: none;
  }
}
  