.welcome-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
}
  
.welcome-text {
    width: 50%;
    justify-content: center;
    margin: 2%;
}

.Portrait-right {
    width: 50%;
    display: block;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-height: 80%;
    margin: 2%;
}




@media screen and (max-width: 900px) {
    .welcome-page {
        flex-direction: column;
    }

    .welcome-text {
        width: 92%;
        margin: 4%;
    }

    .Portrait-right {
        width: 92%;
        margin: 4%;
        height: auto;
        align-self: center;
        vertical-align: middle;
    }

}


@media screen and (max-width: 420px) {
    .welcome-text h1.ant-typography  {
        font-size: 32px !important;
    }

    .welcome-text .ant-typography {
        font-size: 12px !important;
    }
}