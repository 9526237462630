.header {
  top: 0;
  position: sticky;
  width: 100%;
  align-items: center; /* Center align the items vertically */
  justify-content: center; /* Center align the items horizontally */
  height: auto;
  z-index: 100000;
  display: flex;
  flex-grow: 1;
}