
.contact-icons-container {
    font-size: 36px; 
    display: flex;
    justify-content: center;
}

.contact-icons-container > svg {
    padding: 10%; 
}

.github-icon, .linkedin-icon, .instagram-icon, .discord-icon, .twitter-icon, .email-icon {
    text-decoration: none;
    color: inherit; 
    align-self: center;
    padding: 10%;
}

@media screen and (max-width: 600px) {
    .contact-icons-container {
        font-size: 20px;
    }
}